@import '../../styles/typography';
@import '../../styles/colors';

.pageFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  &_left {
    display: grid;
    grid-gap: 16px;

    &__column {
      position: relative;
      cursor: pointer;
    }
  }

  &_right {
    display: flex;
    &_loader {
      margin-right: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button {
      padding: 10px 26px;
      font-size: 13px;
      line-height: 19px;

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  &_component {
    width: 404px;
  }

  &_select {
    height: 38px;
    min-height: initial;
    cursor: pointer;
  }

  &_amount {
    position: absolute;
    width: 360px;
    padding: 24px 24px 26px;
    top: calc(100% + 10px);
    box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.04),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    visibility: hidden;
    opacity: 0;
    z-index: 9;
    background-color: #fff;
    cursor: pointer;

    &__visible {
      visibility: visible;
      opacity: 1;
    }

    &_chevron {
      position: absolute;
      width: 16px;
      height: 16px;
      right: 12px;
      top: 50%;
      transition: all 0.2s ease-in-out;
      transform: translateY(-50%);
      stroke: $licorice-3;

      &_open {
        transform: translateY(-50%) rotateX(-180deg);
      }
    }

    &_error {
      &_message {
        font-size: 12px;
        line-height: 19px;
        letter-spacing: -0.0675px;
        color: #db4343;
        margin: 10px 0;
      }

      .pageFilter_amount_top {
        margin-bottom: 0;
      }

      .pageFilter_amount_trigger {
        border: 1px solid #db4343;
      }
    }

    &_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 26px;

      &_only {
        margin-bottom: 0;
      }
    }

    &_input {
      width: calc(50% - 8px);
    }

    &_bottom {
      padding-right: 10px;
    }

    &_switch {
      justify-content: space-between;

      p {
        line-height: 19px;
        margin-bottom: 0;
      }

      &:first-of-type {
        margin-bottom: 28px;
      }
    }

    &_input {
      input {
        height: 40px;
        border: none;
        background-color: $soap-1;
      }
    }

    &_trigger {
      padding: 10px 12px;
      border: none;
      border-radius: 4px;
      font-size: 13px;
      line-height: 1.46;
      background-color: $soap-1;
      font-weight: 400;
      text-overflow: ellipsis;

      &:active,
      &:focus {
        border-color: $br-money-accent-5;
        background-color: $br-money-accent-1;
      }

      &:focus {
        box-shadow: 0 0 0 3px $br-money-accent-1;
      }
    }
  }
}
