@import '../../styles/colors';
@import '../../styles/typography';

.datePicker {
  position: relative;

  &_wrapper {
    width: 100%;

    &_leftLabel {
      display: flex;
      align-items: center;

      & > label {
        margin-right: 20px;
      }
    }
  }

  &_label {
    @include body-2;
    color: $licorice-6;
    font-weight: 500;
    margin-bottom: 4px;
    cursor: pointer;
  }

  &__light {
    border: 1px solid $soap-2;
    border-radius: 4px;

    .datePicker_trigger {
      background-color: $white-1;

      &:disabled {
        background-color: $soap-1;
      }
    }
  }

  &__right {
    .datePicker_component {
      right: 0;
    }
  }

  &_trigger {
    padding: 10px 12px;
    border: none;
    border-radius: 4px;
    font-size: 13px;
    line-height: 1.46;
    background-color: $soap-1;
    font-weight: 400;
    width: 100%;
    cursor: pointer;
    height: 40px !important;

    &:active,
    &:focus {
      border-color: $blue-5;
      background-color: $blue-1;
    }

    &:focus {
      box-shadow: 0 0 0 3px $blue-2;
    }
  }

  &_component {
    position: absolute;
    width: 100%;
    top: calc(100% + 10px);
    visibility: hidden;
    opacity: 0;
    z-index: 9;

    &__visible {
      visibility: visible;
      opacity: 1;
    }

    header {
      background-color: $white-1;
    }
  }

  &_chevron {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 12px;
    top: 50%;
    transition: all 0.2s ease-in-out;
    transform: translateY(-50%);
    stroke: $text-light;

    &_open {
      transform: translateY(-50%) rotateX(-180deg);
    }
  }
}
