@import '../../styles/colors';

.amountInput {
  input {
    height: 40px;
  }

  &_light {
    input {
      border: none;
      background-color: $soap-1;
    }
  }
  &_default {
    input {
      background-color: $white-1;
    }
  }
}
