@import "../../styles/colors";

.Spinner{
  display: inline-block;
  fill: none;
  stroke: $theme-color;
  stroke-linecap: round;
  stroke-width: 3;
  flex: 0 0 auto;
  pointer-events: none;
  user-select: none;
  vertical-align: middle;
  animation: rotate 1s linear infinite, fade 0.2s ease-in-out;
}

@keyframes rotate {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

@keyframes fade {
  from {opacity: 0;}
  to {opacity: 1;}
}