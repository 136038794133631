@import '../../../../styles/typography';
@import '../../../../styles/colors';

.TransactionDetailsFlyout {
  button[data-testid='flyout-close-button'] {
    z-index: 100;
  }
  &_container {
    height: 100%;
    overflow-y: scroll;
  }
  &_head {
    position: sticky;
    top: 0px;
    width: 100%;
    height: 144px;
    background-color: $br-money-6;
    display: flex;
    padding: 32px;
    justify-content: flex-end;
    &_dropdown {
      display: inline;
      position: absolute;
    }
    &_option {
      display: inline-block;
      * {
        fill: #fff;
      }
    }
  }
  &_card {
    height: 193px;
    width: 100%;
    margin-top: -50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &_container {
      position: sticky;
      top: 94px;
      padding: 0 32px;
      background-image: linear-gradient(
        180deg,
        rgba(#fff, 0) 50%,
        rgba(#fff, 1)
      );
      box-shadow: -5px 10px 5px rgba(#fff, 1);
    }
    h5 {
      @include heading-5;
      margin: 12px 0px 16px 0px;
    }
    span {
      @include caps-2;
      color: $licorice-4;
    }
  }
  &_info {
    padding: 0px 32px;
    margin: 27px 0px;
    &_header {
      @include caps-2;
      color: $licorice-3;
      margin-bottom: 12px;
    }
    &_value {
      font-weight: 500;
    }
    &_red {
      color: $red-8;
    }
    &_green {
      color: $green-6;
    }
    &_item {
      @include body-2;
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
    }
  }
  &_actions {
    position: sticky;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    padding: 50px 32px 24px 32px;
    position: sticky;
    background-image: linear-gradient(
      180deg,
      rgba(#fff, 0.2),
      rgba(#fff, 1) 40%
    );
  }
}
