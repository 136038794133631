@import '../../../../styles/typography';
@import '../../../../styles/colors';

.Transactions {
  padding: 45px 212px 90px 66px;

  &_heading {
    text-align: center;
    &_text {
      @include heading-1;
      margin-bottom: 12px;
    }
    &_sub {
      @include body-1;
    }
    margin-bottom: 56px;
  }
  &_datepicker {
    width: 404px;
  }
  &_filter {
    margin-bottom: 24px;
  }
  &_pagination {
    justify-content: flex-end;
  }
  &_loader {
    height: 288px;
  }
}

.TransactionsList {
  padding: 40px;
  &_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
    &_text {
      @include heading-5;
    }
    &_action {
      @include body-1;
      color: $br-money-accent-7;
      text-decoration: underline;
    }
  }
  &_sub_header {
    @include caps-2;
    padding: 16px;
    color: $licorice-3;
  }
  &_item {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &_content {
      display: flex;
      &_title {
        @include body-1;
        font-weight: 500;
        margin-left: 8px;
        p {
          @include body-2;
        }
      }
    }
    &_side {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
      &_title {
        @include body-1;
        font-weight: 500;
        margin-right: 8px;
      }
      p {
        @include body-2;
      }
    }
  }
}
