@import '../../../../styles/typography';
@import '../../../../styles/colors';

.TransactionPane {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &_content {
    display: flex;
    &_title {
      @include body-1;
      font-weight: 500;
      margin-left: 8px;
      p {
        @include body-2;
      }
    }
  }
  &_side {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    &_title {
      @include body-1;
      font-weight: 500;
      margin-right: 8px;
    }
    p {
      @include body-2;
    }
  }
}
