@import 'colors';
@import 'breakpoint';
@import 'normalize';
@import 'typography';

html {
  font-size: 100%;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

::selection {
  background: $theme-color;
  color: #fff;
  text-shadow: none;
}

body {
  font-family: 'Matter SQ', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  color: $text-dark;
  max-width: 1440px;
  margin: 0 auto !important;
  overflow-x: hidden;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
  line-height: 1.5;
}

*:disabled {
  cursor: not-allowed;
}

button {
  cursor: pointer;

  &:focus,
  &:visited,
  &:active {
    outline: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 500;
  color: $text-dark;
}

h1 {
  @include heading-1;
}
h2 {
  @include heading-2;
}
h3 {
  @include heading-3;
}
h4 {
  @include heading-4;
}
h5 {
  @include heading-5;
}
p {
  @include body-1;
}

a {
  cursor: pointer;
}

strong {
  color: $text-dark;
  font-weight: 500;
}

input[type='checkbox'] {
  -moz-appearance: checkbox;
  -webkit-appearance: checkbox;
  cursor: pointer;
}

// Some flyout component adjustment
._3so9f {
  width: 976px !important;
}

// Phone input
.flag-dropdown {
  background-color: $white-1 !important;
}
