$br-yellow: #ffe500;
$br-orange: #ff9900;
$br-red-orange: #fb5a2d;
$br-pink: #f4cbcf;
$br-green-grey: #e2ede2;

// Brass money colors
$br-money-1: #dfdeed;
$br-money-2: #938dc3;
$br-money-3: #564f97;
$br-money-4: #433d75;
$br-money-5: #302c54;
$br-money-6: #23203d;

// Brass money colors
$br-money-accent-1: #fff7f5;
$br-money-accent-2: #ffe8e0;
$br-money-accent-3: #ffc5b3;
$br-money-accent-4: #ffa285;
$br-money-accent-5: #ff8057;
$br-money-accent-6: #f46a35;
$br-money-accent-7: #f3622a;

// Blue colors
$blue-1: #ecf2fe;
$blue-2: #c9dcfd;
$blue-3: #9ec0fa;
$blue-4: #71a2f4;
$blue-5: #3e7eee;
$blue-6: #2c65c9;

// Orange colors
$orange-1: #ffeed9;
$orange-2: #fcd49f;
$orange-3: #ffbc63;
$orange-4: #ffa126;
$orange-5: #f38b00;
$orange-6: #c06c00;

// Green colors
$green-1: #def7ea;
$green-2: #b1eccd;
$green-3: #74dda6;
$green-4: #0ea84c;
$green-5: #1fad64;
$green-6: #228753;

// Red colors
$red-1: #fcf8f8;
$red-2: #fce9e9;
$red-3: #fad7d7;
$red-4: #f3a5a5;
$red-5: #ed7d7d;
$red-6: #e85656;
$red-7: #db4343;
$red-8: #c63434;

// Licorice colors
$licorice-1: #c9cfd4;
$licorice-2: #7b828e;
$licorice-3: #656b76;
$licorice-4: #50555e;
$licorice-5: #1a1c1f;
$licorice-6: #1a1c1f;

// Soap colors
$soap-1: #f9fafa;
$soap-2: #ebedef;
$soap-3: #e0e3e6;
$soap-4: #d4d9dd;
$soap-5: #c9cfd4;
$soap-6: #7b828e;

// Coffee colors
$coffee-1: #f0e9e9;
$coffee-2: #8b5c5d;

// Pink colors
$pink-5: #f5bcba;
$pink-6: #dba5ad;

// White Colors
$white-1: #ffffff;

// Black Colors
$black-1: #060809;

// Border Colors
$border-color: #edeff3;

$theme-color: $br-money-6;
$theme-light: $soap-1;
$danger: $red-5;
$success: $green-4;

$text-dark: $licorice-6;
$text-light: $licorice-4;
