@import '../../styles/typography';
@import '../../styles/colors';

.emptyScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 112px 0;

  &_icon {
    width: 144px;
    height: 144px;
    margin-bottom: 24px;

    svg {
      width: 144px;
      height: 144px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  &_title {
    @include heading-3;
    margin-bottom: 12px;
    color: $licorice-6;
  }

  &_text {
    @include body-1;
    width: 35ch;
  }

  &_button {
    margin-top: 40px;
    letter-spacing: -0.08px;
    min-width: 172px;
  }
}
