@import '../../../../styles/typography';
@import '../../../../styles/colors';

.ReportTransactionModal {
  header,
  section {
    padding: 10px 40px;
  }
  header {
    padding-top: 60px;
  }
  footer {
    padding: 30px 60px;
  }
  &_form {
    padding: 0px 20px;
    &_input {
      margin-bottom: 20px;
    }
  }
}
