@import '../../styles/typography';

.PageLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  text-align: center;
  position: relative;
  z-index: 99;
  background-color: #fff;

  &_msg {
    @include body-1();
    margin-top: 12px;
  }
}
