@import '../../styles/typography';
@import '../../styles/colors';

.Modal {
  min-height: unset;

  & > button {
    right: 0 !important;
    top: -36px !important;
  }

  &_medium {
    width: 545px !important;
  }

  &_small {
    width: 488px !important;
  }

  &Layout {
    width: 100%;
    position: relative;
    &_top_image {
      width: 100%;
    }
    &_header,
    &_footer,
    &_content {
      width: 100%;
      padding: 24px 32px;
    }

    &_content {
      overflow-y: scroll;
      height: max-content;
      max-height: calc(100vh - 316px);
    }

    &_header {
      padding-top: 68px;

      &_center {
        text-align: center;
      }

      &_left {
        text-align: left;
      }

      &_right {
        text-align: right;
      }

      &_title {
        @include heading-3;
        color: $text-dark;
      }
      &_description {
        @include body-1;
        margin-top: 8px;
        color: $text-dark;
      }
    }

    &_footer {
      border-top: 1px solid $soap-1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;

      &_full {
        display: block;

        &_button {
          width: 100% !important;
        }
      }
    }

    &_medium {
      & > footer {
        justify-content: flex-end;

        & > button {
          min-width: 160px;

          &:nth-of-type(1) {
            margin-right: 16px;
          }
        }
      }
    }

    &_small {
      & > header {
        text-align: center;
      }
      & > footer {
        justify-content: space-between;

        & > button {
          width: 200px;
        }
      }
    }

    &_empty {
      & > header {
        border: 0;
        padding-bottom: 48px;
      }
    }
  }
}
